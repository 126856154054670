.btn-self{
    width:100%;
    padding:10px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;;
    color: #fff;
    margin:0 auto;
}

.btn-self:hover{
    text-decoration: underline;
}

.btn-self_active{
    width:100%;
    padding:10px;
    color: #fff;
}